import { WEBSITE_URL } from './defaults';

export const createURL = (url, path) => {
    return new URL(path, url).href;
};

export const createWebsiteURL = (path) => {
    return createURL(WEBSITE_URL, path);
};

export const createUrlWithParams = (basePath, params) => {
    const query = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    return `${basePath}?${query}`;
};

